import $ from "jquery";

window.JAM = window.JAM || {};
window.JAM.components = window.JAM.components || {};

window.JAM.components.common = (() => {
  const messagesContainerSelector = "#messages_container",
    autoHideMessageSelector = "#messages_container [data-auto-hide]";

  const init = () => {
    initLinks();
    validateForms();
    clearModalFormsOnClose();
    autoHideServerMessages();
  };

  const initLinks = () => {
    $("[data-link]").on("click", () => {
      window.location.href = $(this).data("link");
    });
  };

  const validateForms = () => {
    $("form[data-validate]").each(() => {
      $(this).find('input[type="submit"]').prop("disabled", true);
    });
  };

  const recaptchaSuccess = () => {
    $("form[data-validate]")
      .find('input[type="submit"]')
      .prop("disabled", false);
  };

  const clearModalFormsOnClose = () => {
    $("[data-reveal][data-clear-form]").on("closed.zf.reveal", () => {
      clearFormsInContainer(this);
    });
  };

  const clearFormsInContainer = (container) => {
    $(container)
      .find("form")
      .each(() => {
        $(this)[0].reset();
        if ($(this).attr("data-validate")) {
          $(this).find('input[type="submit"]').prop("disabled", true);
        }
      });
    grecaptcha.reset();
  };

  const clearFormsInContainerById = (containerId) => {
    var container = $(containerId);
    if (container) {
      clearFormsInContainer(container);
    }
  };

  const showServerMessages = (messageContent) => {
    $(messagesContainerSelector).append(messageContent);
    autoHideServerMessages();
  };

  const showFlashMessage = (messageText, messageType = "success") => {
    const messageContent = `
    <div class="alert alert-dismissible fade show mt-3 alert-${messageType}" role="alert" data-auto-hide="true">
        <p class="mb-0">${messageText}</p>
        <button class="close" aria-label="Close" data-dismiss="alert" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    `;
    $(messagesContainerSelector).append(messageContent);
    autoHideServerMessages();
  };

  const autoHideServerMessages = () => {
    if ($(autoHideMessageSelector).length) {
      $(autoHideMessageSelector).each((index, message) => {
        let $message = $(message);
        setTimeout(() => {
          $($message).fadeOut("medium", function () {
            $(this).remove();
          });
        }, 10000);
      });
    }
  };

  const scrollToTop = () => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  };

  const showLoader = () => {
    $("#loader").show();
  };

  const hideLoader = () => {
    $("#loader").hide();
  };

  return {
    init,
    recaptchaSuccess,
    showServerMessages,
    scrollToTop,
    clearFormsInContainer,
    clearFormsInContainerById,
    showFlashMessage,
    showLoader,
    hideLoader,
  };
})();

$(function () {
  window.JAM.components.common.init();
});

window.recaptchaSuccess = JAM.components.common.recaptchaSuccess;
